<template>
  <div class="container">
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      :inline="true"
      label-position="right"
      label-width="180px"
    >
      <!-- 清扫保洁标段情况 -->
      <div style="margin-bottom: 20px">
        <div class="second-title">清扫保洁标段情况</div>
        <div class="form-top">
          <div class="three-title">面积与单价</div>
          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="12" :lg="7" :xl="7">
              <el-form-item label="清扫保洁面积(平方米)" prop="cleanArea">
                <el-input
                  v-model.number="form.cleanArea"
                  type="number"
                  placeholder="请输入"
                  maxlength="7"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="7" :xl="7">
              <el-form-item label="平均单价(元/年.平方米)" prop="cleanPrice">
                <el-input
                  v-model.number="form.cleanPrice"
                  type="number"
                  placeholder="请输入"
                  maxlength="7"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="four-title">特级路</div>
          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="12" :lg="7" :xl="7">
              <el-form-item label="面积(万平米)" prop="cleanSuperRoadArea">
                <el-input
                  v-model.number="form.cleanSuperRoadArea"
                  type="number"
                  placeholder="请输入"
                  maxlength="7"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="7" :xl="7">
              <el-form-item label="单价(元/年.平方米)" prop="cleanSuperRoadPrice">
                <el-input
                  v-model.number="form.cleanSuperRoadPrice"
                  type="number"
                  placeholder="请输入"
                  maxlength="7"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="10" :xl="10">
              <el-form-item label="说明" prop="cleanSuperRoadRemark">
                <el-input v-model="form.cleanSuperRoadRemark" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <div class="four-title">一级路</div>
          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="12" :lg="7" :xl="7">
              <el-form-item label="面积(万平米)" prop="clean1stRoadArea">
                <el-input
                  v-model.number="form.clean1stRoadArea"
                  type="number"
                  placeholder="请输入"
                  maxlength="7"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="7" :xl="7">
              <el-form-item label="单价(元/年.平方米)" prop="clean1stRoadPrice">
                <el-input
                  v-model.number="form.clean1stRoadPrice"
                  type="number"
                  placeholder="请输入"
                  maxlength="7"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="10" :xl="10">
              <el-form-item label="说明" prop="clean1stRoadRemark">
                <el-input v-model="form.clean1stRoadRemark" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="four-title">二级路</div>
          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="12" :lg="7" :xl="7">
              <el-form-item label="面积(万平米)" prop="clean2stRoadArea">
                <el-input
                  v-model.number="form.clean2stRoadArea"
                  type="number"
                  placeholder="请输入"
                  maxlength="7"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="7" :xl="7">
              <el-form-item label="单价(元/年.平方米)" prop="clean2stRoadPrice">
                <el-input
                  v-model.number="form.clean2stRoadPrice"
                  type="number"
                  placeholder="请输入"
                  maxlength="7"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="10" :xl="10">
              <el-form-item label="说明" prop="clean2stRoadRemark">
                <el-input
                  v-model="form.clean2stRoadRemark"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="four-title">三级路</div>
          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="12" :lg="7" :xl="7">
              <el-form-item label="面积(万平米)" prop="clean3stRoadArea">
                <el-input
                  v-model.number="form.clean3stRoadArea"
                  type="number"
                  placeholder="请输入"
                  maxlength="7"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="7" :xl="7">
              <el-form-item label="单价(元/年.平方米)" prop="clean3stRoadPrice">
                <el-input
                  v-model.number="form.clean3stRoadPrice"
                  type="number"
                  placeholder="请输入"
                  maxlength="7"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="10" :xl="10">
              <el-form-item label="说明" prop="clean3stRoadRemark">
                <el-input
                  v-model="form.clean3stRoadRemark"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="four-title">四级路</div>
          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="12" :lg="7" :xl="7">
              <el-form-item label="面积(万平米)" prop="clean4stRoadArea">
                <el-input
                  v-model.number="form.clean4stRoadArea"
                  type="number"
                  placeholder="请输入"
                  maxlength="7"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="7" :xl="7">
              <el-form-item label="单价(元/年.平方米)" prop="clean4stRoadPrice">
                <el-input
                  v-model.number="form.clean4stRoadPrice"
                  type="number"
                  placeholder="请输入"
                  maxlength="7"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="10" :xl="10">
              <el-form-item label="说明" prop="clean4stRoadRemark">
                <el-input
                  v-model="form.clean4stRoadRemark"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="four-title">城中村</div>
          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="12" :lg="7" :xl="7">
              <el-form-item label="面积(万平米)" prop="cleanVillageArea">
                <el-input
                  v-model.number="form.cleanVillageArea"
                  type="number"
                  placeholder="请输入"
                  maxlength="7"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="7" :xl="7">
              <el-form-item label="单价(元/年.平方米)" prop="cleanVillagePrice">
                <el-input
                  v-model.number="form.cleanVillagePrice"
                  type="number"
                  placeholder="请输入"
                  maxlength="7"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="10" :xl="10">
              <el-form-item label="说明" prop="cleanVillageRemark">
                <el-input v-model="form.cleanVillageRemark" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="four-title">市政道路扣除广场步行街剩余面积</div>
          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="12" :lg="7" :xl="7">
              <el-form-item label="面积(万平米)" prop="cleanLeftArea">
                <el-input
                  v-model.number="form.cleanLeftArea"
                  type="number"
                  placeholder="请输入"
                  maxlength="7"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="7" :xl="7">
              <el-form-item label="单价(元/年.平方米)" prop="cleanLeftPrice">
                <el-input
                  v-model.number="form.cleanLeftPrice"
                  type="number"
                  placeholder="请输入"
                  maxlength="7"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="10" :xl="10">
              <el-form-item label="说明" prop="cleanLeftRemark">
                <el-input v-model="form.cleanLeftRemark" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="four-title">绿地面积</div>
          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="12" :lg="7" :xl="7">
              <el-form-item label="面积(万平米)" prop="cleanGreenArea">
                <el-input
                  v-model.number="form.cleanGreenArea"
                  type="number"
                  placeholder="请输入"
                  maxlength="7"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="7" :xl="7">
              <el-form-item label="单价(元/年.平方米)" prop="cleanGreenPrice">
                <el-input
                  v-model.number="form.cleanGreenPrice"
                  type="number"
                  placeholder="请输入"
                  maxlength="7"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="10" :xl="10">
              <el-form-item label="说明" prop="cleanGreenRemark">
                <el-input v-model="form.cleanGreenRemark" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="three-title">清扫保洁车辆管理</div>
          <div class="four-title">车辆统计</div>
          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="12" :lg="4" :xl="4">
              <span>车辆总数：{{summaryData.cleanVehicleTotalNum|isNull}}</span>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="4" :xl="4">
              <span>行驶证上传数量：{{summaryData.cleanVehicleLicenseUploadedNum|isNull}}</span>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="4" :xl="4">
              <span>清洗车：{{summaryData.cleanVehicleCleaningNum|isNull}}</span>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="4" :xl="4">
              <span>扫路车：{{summaryData.cleanVehicleSweepingNum|isNull}}</span>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="4" :xl="4">
              <span>垃圾收集车：{{summaryData.cleanVehicleCollectingNum|isNull}}</span>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="4" :xl="4">
              <span>管理用车：{{summaryData.cleanVehicleManagementNum|isNull}}</span>
            </el-col>
          </el-row>
          <div class="three-title" style="margin-top: 20px">清扫保洁人员管理</div>
          <div class="four-title">人员统计</div>
          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="12" :lg="4" :xl="4">
              <span>人员总数：{{summaryData.cleanStaffTotalNum|isNull}}</span>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="4" :xl="4">
              <span>身份证上传数量：{{summaryData.cleanStaffIdImageUploadedNum|isNull}}</span>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="4" :xl="4">
              <span>项目经理：{{summaryData.cleanStaffPmNum|isNull}}</span>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="4" :xl="4">
              <span>安全生产管理人员：{{summaryData.cleanStaffSafetyManagerNum|isNull}}</span>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="4" :xl="4">
              <span>一线保洁人员：{{summaryData.cleanStaffCleanerNum|isNull}}</span>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="4" :xl="4">
              <span>车队长：{{summaryData.cleanStaffTeamCaptainNum|isNull}}</span>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="4" :xl="4">
              <span>片区负责人：{{summaryData.cleanStaffDistrictManagerNum|isNull}}</span>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="4" :xl="4">
              <span>作业司机：{{summaryData.cleanStaffTotalNum|isNull}}</span>
            </el-col>
          </el-row>
        </div>
      </div>

      <!-- 垃圾清运标段情况 -->
      <div style="margin-bottom: 20px">
        <div class="second-title">垃圾清运标段情况</div>
        <div class="form-top">
          <div class="three-title">作业量与单价</div>
          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="12" :lg="7" :xl="7">
              <el-form-item label="总吨数" prop="trashTons">
                <el-input
                  v-model.number="form.trashTons"
                  type="number"
                  placeholder="请输入"
                  maxlength="7"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="7" :xl="7">
              <el-form-item label="平均单价(元/年.吨)" prop="trashPrice">
                <el-input
                  v-model.number="form.trashPrice"
                  type="number"
                  placeholder="请输入"
                  maxlength="7"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="four-title">垃圾中转站</div>
          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="12" :lg="7" :xl="7">
              <el-form-item label="数量" prop="trashTransferNum">
                <el-input
                  v-model.number="form.trashTransferNum"
                  type="number"
                  placeholder="请输入"
                  maxlength="7"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="7" :xl="7">
              <el-form-item label="单价(元/年.吨)" prop="trashTransferPrice">
                <el-input
                  v-model.number="form.trashTransferPrice"
                  type="number"
                  placeholder="请输入"
                  maxlength="7"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="10" :xl="10">
              <el-form-item label="说明" prop="trashTransferRemark">
                <el-input v-model="form.trashTransferRemark" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <div class="four-title">公厕</div>
          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="12" :lg="7" :xl="7">
              <el-form-item label="数量" prop="trashWcNum">
                <el-input
                  v-model.number="form.trashWcNum"
                  type="number"
                  placeholder="请输入"
                  maxlength="7"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="7" :xl="7">
              <el-form-item label="单价(元/年.吨)" prop="trashWcPrice">
                <el-input
                  v-model.number="form.trashWcPrice"
                  type="number"
                  placeholder="请输入"
                  maxlength="7"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="10" :xl="10">
              <el-form-item label="说明" prop="trashWcRemark">
                <el-input v-model="form.trashWcRemark" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="four-title">吨数（含前端运输）</div>
          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="12" :lg="7" :xl="7">
              <el-form-item label="数量" prop="trashTonsContainNum">
                <el-input
                  v-model.number="form.trashTonsContainNum"
                  type="number"
                  placeholder="请输入"
                  maxlength="7"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="7" :xl="7">
              <el-form-item label="单价(元/年.吨)" prop="trashTonsContainPrice">
                <el-input
                  v-model.number="form.trashTonsContainPrice"
                  type="number"
                  placeholder="请输入"
                  maxlength="7"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="10" :xl="10">
              <el-form-item label="说明" prop="trashTonsContainRemark">
                <el-input v-model="form.trashTonsContainRemark" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="four-title">吨数（不含前端运输）</div>
          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="12" :lg="7" :xl="7">
              <el-form-item label="数量" prop="trashTonsUncontainNum">
                <el-input
                  v-model.number="form.trashTonsUncontainNum"
                  type="number"
                  placeholder="请输入"
                  maxlength="7"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="7" :xl="7">
              <el-form-item label="单价(元/年.吨)" prop="trashTonsUncontainPrice">
                <el-input
                  v-model.number="form.trashTonsUncontainPrice"
                  type="number"
                  placeholder="请输入"
                  maxlength="7"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="10" :xl="10">
              <el-form-item label="说明" prop="trashTonsUncontainRemark">
                <el-input v-model="form.trashTonsUncontainRemark" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="four-title">运输距离（含前端）</div>
          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="12" :lg="7" :xl="7">
              <el-form-item label="数量" prop="trashDistanceContainNum">
                <el-input
                  v-model.number="form.trashDistanceContainNum"
                  type="number"
                  placeholder="请输入"
                  maxlength="7"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="7" :xl="7">
              <el-form-item label="单价(元/年.吨)" prop="trashDistanceContainPrice">
                <el-input
                  v-model.number="form.trashDistanceContainPrice"
                  type="number"
                  placeholder="请输入"
                  maxlength="7"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="10" :xl="10">
              <el-form-item label="说明" prop="trashDistanceContainRemark">
                <el-input v-model="form.trashDistanceContainRemark" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="four-title">运输距离（不含前端）</div>
          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="12" :lg="7" :xl="7">
              <el-form-item label="数量" prop="trashDistanceUncontainNum">
                <el-input
                  v-model.number="form.trashDistanceUncontainNum"
                  type="number"
                  placeholder="请输入"
                  maxlength="7"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="7" :xl="7">
              <el-form-item label="单价(元/年.吨)" prop="trashDistanceUncontainPrice">
                <el-input
                  v-model.number="form.trashDistanceUncontainPrice"
                  type="number"
                  placeholder="请输入"
                  maxlength="7"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="10" :xl="10">
              <el-form-item label="说明" prop="trashDistanceUncontainRemark">
                <el-input
                  v-model="form.trashDistanceUncontainRemark"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <div class="three-title">垃圾清运车辆管理</div>
          <div class="four-title">车辆统计</div>
          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="12" :lg="4" :xl="4">
              <span>车辆总数：{{summaryData.trashVehicleTotalNum|isNull}}</span>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="4" :xl="4">
              <span>行驶证上传数量：{{summaryData.trashVehicleLicenseUploadedNum|isNull}}</span>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="4" :xl="4">
              <span>自卸平板车：{{summaryData.trashVehicleFlatNum|isNull}}</span>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="4" :xl="4">
              <span>勾臂车：{{summaryData.trashVehicleArmNum|isNull}}</span>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="4" :xl="4">
              <span>密闭式后装式压缩车：{{summaryData.trashVehicleCompressNum|isNull}}</span>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="4" :xl="4">
              <span>桶装垃圾运输车：{{summaryData.trashVehicleBucketNum|isNull}}</span>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="4" :xl="4">
              <span>管理用车：{{summaryData.trashVehicleManagementNum|isNull}}</span>
            </el-col>
          </el-row>
          <div class="three-title" style="margin-top: 20px">垃圾清运人员管理</div>
          <div class="four-title">人员统计</div>
          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="12" :lg="4" :xl="4">
              <span>人员总数：{{summaryData.trashStaffTotalNum|isNull}}</span>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="4" :xl="4">
              <span>身份证上传数量：{{summaryData.trashStaffIdImageUploadedNum|isNull}}</span>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="4" :xl="4">
              <span>项目经理：{{summaryData.trashStaffPmNum|isNull}}</span>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="4" :xl="4">
              <span>车队长：{{summaryData.trashStaffTeamCaptainNum|isNull}}</span>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="4" :xl="4">
              <span>安全生产管理员：{{summaryData.trashStaffSafetyManagerNum|isNull}}</span>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="4" :xl="4">
              <span>垃圾收集工：{{summaryData.trashStaffCollectorNum|isNull}}</span>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="4" :xl="4">
              <span>公厕保洁人员：{{summaryData.trashStaffWcCleanerNum|isNull}}</span>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="4" :xl="4">
              <span>作业司机：{{summaryData.trashStaffDriverNum|isNull}}</span>
            </el-col>
          </el-row>
          <div class="three-title" style="margin-top: 20px">审核信息</div>
          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <span>状态：{{summaryData.checkStatus|isNull}}</span>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <span>审核意见：{{summaryData.checkResult|isNull}}</span>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-form>
    <div style="display:flex;justify-content:center">
      <el-button type="success" class="btn-success-hw" @click="onSubmit">保存</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "DistrictSummaryEdit",
  components: {},
  props: {
    summaryId: String
  },
  data() {
    return {
      nav: [{ name: "编辑汇总信息" }],
      summaryData: {},
      submitLoading: false,
      form: {
        cleanArea: null,
        cleanPrice: null,
        // 特级路
        cleanSuperRoadArea: null,
        cleanSuperRoadPrice: null,
        cleanSuperRoadRemark: null,

        // 一级路
        clean1stRoadArea: null,
        clean1stRoadPrice: null,
        clean1stRoadRemark: null,

        // 二级路
        clean2stRoadArea: null,
        clean2stRoadPrice: null,
        clean2stRoadRemark: null,

        // 三级路
        clean3stRoadArea: null,
        clean3stRoadPrice: null,
        clean3stRoadRemark: null,

        // 四级路
        clean4stRoadArea: null,
        clean4stRoadPrice: null,
        clean4stRoadRemark: null,
        // 城中村
        cleanVillageArea: null,
        cleanVillagePrice: null,
        cleanVillageRemark: null,

        // 市政道路扣除广场步行街剩余面积
        cleanLeftArea: null,
        cleanLeftPrice: null,
        cleanLeftRemark: null,
        // 绿地面积
        cleanGreenArea: null,
        cleanGreenPrice: null,
        cleanGreenRemark: null,

        // 作业量与单价
        trashTons: null,
        trashPrice: null,
        // 垃圾中转站
        trashTransferNum: null,
        trashTransferPrice: null,
        trashTransferRemark: null,
        // 公厕
        trashWcNum: null,
        trashWcPrice: null,
        trashWcRemark: null,
        // 吨数（含前端运输）
        trashTonsContainNum: null,
        trashTonsContainPrice: null,
        trashTonsContainRemark: null,
        // 吨数（不含前端运输）
        trashTonsUncontainNum: null,
        trashTonsUncontainPrice: null,
        trashTonsUncontainRemark: null,
        // 运输距离（含前端）
        trashDistanceContainNum: null,
        trashDistanceContainPrice: null,
        trashDistanceContainRemark: null,
        // 运输距离（不含前端）
        trashDistanceUncontainNum: null,
        trashDistanceUncontainPrice: null,
        trashDistanceUncontainRemark: null
      },
      rules: {
        cleanArea: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        },
        cleanPrice: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        },
        // 特级路
        cleanSuperRoadArea: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        },
        cleanSuperRoadPrice: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        },
        cleanSuperRoadRemark: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        },

        // 一级路
        clean1stRoadArea: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        },
        clean1stRoadPrice: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        },
        clean1stRoadRemark: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        },

        // 二级路
        clean2stRoadArea: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        },
        clean2stRoadPrice: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        },
        clean2stRoadRemark: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        },

        // 三级路
        clean3stRoadArea: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        },
        clean3stRoadPrice: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        },
        clean3stRoadRemark: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        },

        // 四级路
        clean4stRoadArea: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        },
        clean4stRoadPrice: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        },
        clean4stRoadRemark: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        },
        // 城中村
        cleanVillageArea: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        },
        cleanVillagePrice: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        },
        cleanVillageRemark: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        },

        // 市政道路扣除广场步行街剩余面积
        cleanLeftArea: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        },
        cleanLeftPrice: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        },
        cleanLeftRemark: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        },
        // 绿地面积
        cleanGreenArea: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        },
        cleanGreenPrice: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        },
        cleanGreenRemark: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        },

        // 作业量与单价
        trashTons: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        },
        trashPrice: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        },
        // 垃圾中转站
        trashTransferNum: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        },
        trashTransferPrice: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        },
        trashTransferRemark: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        },
        // 公厕
        trashWcNum: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        },
        trashWcPrice: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        },
        trashWcRemark: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        },
        // 吨数（含前端运输）
        trashTonsContainNum: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        },
        trashTonsContainPrice: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        },
        trashTonsContainRemark: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        },
        // 吨数（不含前端运输）
        trashTonsUncontainNum: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        },
        trashTonsUncontainPrice: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        },
        trashTonsUncontainRemark: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        },
        // 运输距离（含前端）
        trashDistanceContainNum: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        },
        trashDistanceContainPrice: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        },
        trashDistanceContainRemark: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        },
        // 运输距离（不含前端）
        trashDistanceUncontainNum: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        },
        trashDistanceUncontainPrice: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        },
        trashDistanceUncontainRemark: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur"
        }
      }
    };
  },
  filters: {
    isNull: value => {
      return value !== 0 && !value ? "--" : value;
    }
  },
  async mounted() {
    await this.getEditForm()
  },
  methods: {
    async getEditForm() {
      // 获取表单信息
      this.summaryData = await this.$api_hw.districtInfo_bidSummaryGet({
        id: this.summaryId
      });
      this.form = this.summaryData;
    },
    async submit() {
      this.form.bidId = this.summaryId;
      delete this.form.gmtCreate;
      delete this.form.gmtModified;
      let params = {
        bidSummaryInfo: this.form
      };
      await this.$api_hw.districtInfo_bidSummaryMerge(params).then(res => {
        this.$notify({
          title: "成功",
          message: `${this.nav[0].name}成功`,
          type: "success"
        });
        this.$refs["form"].resetFields();
        this.$emit("coloseDrawer");
      });
    },
    //提交表单
    async onSubmit() {
      if (this.submitLoading) return; //防止重复提交
      this.$refs.form.validate(async valid => {
        if (!valid) return;
        this.submitLoading = true;
        try {
          await this.submit();
        } catch (e) {
          // console.info(e);
        } finally {
          this.submitLoading = false;
        }
      });
    }
  }
};
</script>

<style lang="sass" scoped>
.container
  padding: 80px 20px 20px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  .form-top
    padding: 15px 20px 20px;
    border: 1px solid #E1E1E1;
    box-sizing: border-box;
    border-radius: 4px;
    .three-title
      font-size: 16px;
      margin-bottom: 15px;
    .four-title
      margin-bottom: 10px;
      font-size: 14px;
      color: #7D8187;
.second-title
  margin-bottom: 20px;
  color: #19BB9B;
  font-size: 18px;
// 覆盖element样式
.el-form-item--mini.el-form-item
  display: flex;
  flex-wrap: nowrap;
/deep/ .el-form-item--mini .el-form-item__content
  flex: 1;
/deep/ .el-input--mini
  width: 100%;
</style>